/* contrail-one-regular - latin */
@font-face {
    font-family: 'Contrail One';
    font-style: normal;
    font-weight: 400;
    src: local('Contrail One'), local('ContrailOne-Regular'), url('../fonts/contrail-one-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/contrail-one-v7-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-mono-regular - latin_latin-ext */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Fira Mono Regular'), local('FiraMono-Regular'),
       url('../fonts/fira-mono-v6-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-mono-v6-latin_latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-mono-500 - latin_latin-ext */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 500;
  src: local('Fira Mono Medium'), local('FiraMono-Medium'),
       url('../fonts/fira-mono-v6-latin_latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-mono-v6-latin_latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-mono-700 - latin_latin-ext */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Fira Mono Bold'), local('FiraMono-Bold'),
       url('../fonts/fira-mono-v6-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-mono-v6-latin_latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* source-sans-pro-200 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-200italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-300 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-300italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// /* source-sans-pro-900 - latin_latin-ext */
// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 900;
//   src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
//        url('../fonts/source-sans-pro-v11-latin_latin-ext-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('../fonts/source-sans-pro-v11-latin_latin-ext-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

// /* source-sans-pro-900italic - latin_latin-ext */
// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: italic;
//   font-weight: 900;
//   src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
//        url('../fonts/source-sans-pro-v11-latin_latin-ext-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('../fonts/source-sans-pro-v11-latin_latin-ext-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

/* source-sans-pro-700italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url('../fonts/source-sans-pro-v11-latin_latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v11-latin_latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
