.codehilite .hll { background-color: #fff }
.codehilite .c { color: #999; font-style: italic } /* Comment */
.codehilite .err { color: $red; background-color: $white } /* Error */
.codehilite .k { color: $black; font-weight: bold } /* Keyword */
.codehilite .o { color: $black; font-weight: bold } /* Operator */
.codehilite .cm { color: #999; font-style: italic } /* Comment.Multiline */
.codehilite .cp { color: #999; font-weight: bold; font-style: italic } /* Comment.Preproc */
.codehilite .c1 { color: #999; font-style: italic } /* Comment.Single */
.codehilite .cs { color: #999; font-weight: bold; font-style: italic } /* Comment.Special */
.codehilite .gd { color: $black; background-color: $white } /* Generic.Deleted */
.codehilite .ge { color: $black; font-style: italic } /* Generic.Emph */
.codehilite .gr { color: $red } /* Generic.Error */
.codehilite .gh { color: #999 } /* Generic.Heading */
.codehilite .gi { color: $black; background-color: $white } /* Generic.Inserted */
.codehilite .go { color: #888 } /* Generic.Output */
.codehilite .gp { color: #555 } /* Generic.Prompt */
.codehilite .gs { font-weight: bold } /* Generic.Strong */
.codehilite .gu { color: #aaa } /* Generic.Subheading */
.codehilite .gt { color: $red } /* Generic.Traceback */
.codehilite .kc { color: $black; font-weight: bold } /* Keyword.Constant */
.codehilite .kd { color: $black; font-weight: bold } /* Keyword.Declaration */
.codehilite .kn { color: $black; font-weight: bold } /* Keyword.Namespace */
.codehilite .kp { color: $black; font-weight: bold } /* Keyword.Pseudo */
.codehilite .kr { color: $black; font-weight: bold } /* Keyword.Reserved */
.codehilite .kt { color: $blue; font-weight: bold } /* Keyword.Type */
.codehilite .m { color: $blue } /* Literal.Number */
.codehilite .s { color: $red } /* Literal.String */
.codehilite .na { color: $blue } /* Name.Attribute */
.codehilite .nb { color: $blue } /* Name.Builtin */
.codehilite .nc { color: $blue; font-weight: bold } /* Name.Class */
.codehilite .no { color: $blue } /* Name.Constant */
.codehilite .nd { color: $blue; font-weight: bold } /* Name.Decorator */
.codehilite .ni { color: $red } /* Name.Entity */
.codehilite .ne { color: $red; font-weight: bold } /* Name.Exception */
.codehilite .nf { color: $red; font-weight: bold } /* Name.Function */
.codehilite .nl { color: $red; font-weight: bold } /* Name.Label */
.codehilite .nn { color: #555 } /* Name.Namespace */
.codehilite .nt { color: $blue } /* Name.Tag */
.codehilite .nv { color: $blue } /* Name.Variable */
.codehilite .ow { color: $black; font-weight: bold } /* Operator.Word */
.codehilite .w { color: #bbb } /* Text.Whitespace */
.codehilite .mf { color: $blue } /* Literal.Number.Float */
.codehilite .mh { color: $blue } /* Literal.Number.Hex */
.codehilite .mi { color: $blue } /* Literal.Number.Integer */
.codehilite .mo { color: $blue } /* Literal.Number.Oct */
.codehilite .sb { color: $red } /* Literal.String.Backtick */
.codehilite .sc { color: $red } /* Literal.String.Char */
.codehilite .sd { color: $red } /* Literal.String.Doc */
.codehilite .s2 { color: $red } /* Literal.String.Double */
.codehilite .se { color: $red } /* Literal.String.Escape */
.codehilite .sh { color: $red } /* Literal.String.Heredoc */
.codehilite .si { color: $red } /* Literal.String.Interpol */
.codehilite .sx { color: $red } /* Literal.String.Other */
.codehilite .sr { color: $red } /* Literal.String.Regex */
.codehilite .s1 { color: $red } /* Literal.String.Single */
.codehilite .ss { color: $red } /* Literal.String.Symbol */
.codehilite .bp { color: #999 } /* Name.Builtin.Pseudo */
.codehilite .vc { color: $blue } /* Name.Variable.Class */
.codehilite .vg { color: $blue } /* Name.Variable.Global */
.codehilite .vi { color: $blue } /* Name.Variable.Instance */
.codehilite .il { color: $blue } /* Literal.Number.Integer.Long */
