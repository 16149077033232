@font-face {
  font-family: Contrail One;
  font-style: normal;
  font-weight: 400;
  src: local(Contrail One), local(ContrailOne-Regular), url("contrail-one-v7-latin-regular.5e613308.woff2") format("woff2"), url("contrail-one-v7-latin-regular.44768df1.woff") format("woff");
}

@font-face {
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local(Fira Mono Regular), local(FiraMono-Regular), url("fira-mono-v6-latin_latin-ext-regular.f7333468.woff2") format("woff2"), url("fira-mono-v6-latin_latin-ext-regular.3965736e.woff") format("woff");
}

@font-face {
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 500;
  src: local(Fira Mono Medium), local(FiraMono-Medium), url("fira-mono-v6-latin_latin-ext-500.67210f38.woff2") format("woff2"), url("fira-mono-v6-latin_latin-ext-500.7b709791.woff") format("woff");
}

@font-face {
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 700;
  src: local(Fira Mono Bold), local(FiraMono-Bold), url("fira-mono-v6-latin_latin-ext-700.dd26eb22.woff2") format("woff2"), url("fira-mono-v6-latin_latin-ext-700.cbd7508c.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 200;
  src: local(Source Sans Pro ExtraLight), local(SourceSansPro-ExtraLight), url("source-sans-pro-v11-latin_latin-ext-200.15f94436.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-200.17caaa53.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 200;
  src: local(Source Sans Pro ExtraLight Italic), local(SourceSansPro-ExtraLightItalic), url("source-sans-pro-v11-latin_latin-ext-200italic.d4d95f39.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-200italic.7b027d19.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  src: local(Source Sans Pro Light), local(SourceSansPro-Light), url("source-sans-pro-v11-latin_latin-ext-300.2cd6fb96.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-300.2e9a3cc3.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 300;
  src: local(Source Sans Pro Light Italic), local(SourceSansPro-LightItalic), url("source-sans-pro-v11-latin_latin-ext-300italic.61cba7e4.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-300italic.eece8722.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  src: local(Source Sans Pro Regular), local(SourceSansPro-Regular), url("source-sans-pro-v11-latin_latin-ext-regular.cdb236e5.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-regular.9ff60c60.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  src: local(Source Sans Pro Italic), local(SourceSansPro-Italic), url("source-sans-pro-v11-latin_latin-ext-italic.23f943ef.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-italic.901db5de.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 600;
  src: local(Source Sans Pro SemiBold Italic), local(SourceSansPro-SemiBoldItalic), url("source-sans-pro-v11-latin_latin-ext-600italic.12be91aa.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-600italic.d24a243a.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  src: local(Source Sans Pro Bold), local(SourceSansPro-Bold), url("source-sans-pro-v11-latin_latin-ext-700.6d7cc538.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-700.45b680b3.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  src: local(Source Sans Pro SemiBold), local(SourceSansPro-SemiBold), url("source-sans-pro-v11-latin_latin-ext-600.5a2d1595.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-600.d348ce27.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 700;
  src: local(Source Sans Pro Bold Italic), local(SourceSansPro-BoldItalic), url("source-sans-pro-v11-latin_latin-ext-700italic.b83423a8.woff2") format("woff2"), url("source-sans-pro-v11-latin_latin-ext-700italic.5e5d7b0c.woff") format("woff");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  hyphens: auto;
}

body, html {
  color: #202020;
  background-color: #ededed;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

b, strong {
  font-weight: 600;
}

code, pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background-color: #fff;
  padding: 3px 4px 2px;
  font-family: Fira Mono, monospace;
  font-size: .8em;
}

div.codehilite pre {
  padding: 4px 6px;
}

button, input {
  padding: 5px 10px;
  font-size: 18px;
  line-height: 1.5;
}

ul.errors {
  margin-left: 0;
  padding: 0;
  list-style-type: none;
}

ul.errors li {
  color: #bb381c;
  margin: 0;
  list-style-type: none;
  list-style-position: outside;
}

ul.navigation {
  column-count: 2;
  color: #bb381c;
  text-transform: uppercase;
  hyphens: none;
  column-gap: 1.4em;
  margin: 0 0 0 .5em;
  padding: 1.5em 0 0;
  font-size: .8em;
  font-weight: 600;
}

ul.navigation li {
  list-style: decimal-leading-zero;
}

ul.navigation li a {
  color: #202020;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

ul.navigation li a.blue {
  color: #17679b;
}

ul.navigation li a.blue:hover {
  color: #1e85c7;
}

ul.navigation li a.red {
  color: #bb381c;
}

ul.navigation li a.red:hover {
  color: #df4b2b;
}

ul.navigation li a:hover {
  color: #535353;
}

@media (width <= 1023px) {
  .toc {
    display: none;
  }
}

@media (width >= 1024px) {
  .toc {
    background-color: #fff;
    margin: 1em 0;
    padding: .1em 0 .2em;
  }

  .toc ul, .toc ol {
    column-count: 2;
    color: #6d6d6d;
    column-gap: 1.4em;
    margin: .4em 1em .4em .5em;
    font-size: .85em;
    font-weight: 600;
  }

  .toc ul li, .toc ol li {
    margin-bottom: .1em;
    list-style: disc;
  }

  .toc ul li a, .toc ol li a {
    color: #202020;
    text-decoration: none;
  }

  .toc ul li a.red, .toc ol li a.red {
    color: #bb381c;
  }

  .toc ul li a.red:hover, .toc ol li a.red:hover {
    color: #df4b2b;
  }

  .toc ul li a:hover, .toc ol li a:hover {
    color: #535353;
  }
}

.logo {
  color: #bb381c;
  text-align: center;
  text-transform: uppercase;
  background-color: #ededed;
  width: 100px;
  height: 106px;
  padding: 0;
  font-family: Contrail One, sans-serif;
  font-size: 1.86em;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0 0 0 2px #bb381c;
}

.logo:hover {
  color: #bb381c;
}

.inner {
  color: #ededed;
  background-color: #bb381c;
  height: 53px;
  padding-top: 3px;
  display: block;
}

h1 {
  text-transform: uppercase;
  margin: .75em 0;
  padding: 0;
  font-family: Contrail One, sans-serif;
  font-size: 3.815em;
  line-height: 1.1;
  overflow: hidden;
}

h2 {
  margin: 1.414em 0 .5em;
  font-family: Contrail One, sans-serif;
  font-size: 1.953em;
  line-height: 1.5;
}

h2 a {
  color: #bb381c;
  text-decoration: none;
}

h2 a:hover {
  color: #df4b2b;
}

h3 {
  margin: 1.414em 0 .5em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.5;
}

h4 {
  margin: 1.414em 0 .5em;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.5;
}

div.codehilite, p, dl, ol, ul {
  margin-bottom: 18px;
  font-size: 18px;
}

.small {
  font-size: .8em;
}

.center {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.right {
  text-align: right;
  width: 100%;
  display: inline-block;
}

.wrapper {
  margin: 0 auto;
  padding: 18px;
  position: relative;
}

.red {
  color: #bb381c;
}

ol, ul {
  margin-left: 18px;
}

ul {
  list-style-type: square;
}

ol > li, ul > li {
  margin-left: 18px;
}

a {
  color: #17679b;
  font-weight: 400;
  text-decoration: none;
}

a.headerlink {
  color: #868686;
  font-weight: 300;
  text-decoration: none;
}

a.headerlink:before {
  content: " ";
}

a.headerlink:hover {
  color: #17679b;
}

a:hover {
  color: #1e85c7;
}

a.github {
  color: #202020;
  font-size: 1.25em;
  font-weight: 600;
  text-decoration: none;
}

::selection {
  color: #ededed;
  background: #bb381c;
}

.c1 {
  width: 260px;
  margin: 0 auto;
}

.sidebar {
  text-align: left;
  z-index: 2;
  max-width: 300px;
}

@media (width <= 1023px) {
  body > .wrapper .c1 {
    display: none;
  }
}

@media (width <= 600px) {
  .c2, .c4 {
    margin-top: 130px;
  }
}

@media (width >= 601px) and (width <= 1023px) {
  body {
    -webkit-text-size-adjust: none;
  }

  .wrapper {
    max-width: 601px;
  }

  .wrapper .c1, .wrapper .c2, .wrapper .c4 {
    float: left;
  }

  .wrapper .c2 {
    width: 300px;
    padding: 30px 20px 0 50px;
  }

  .wrapper .c3 {
    padding: 122px 0 0 50px;
  }

  .wrapper .c2, .wrapper .c3 {
    text-align: left;
  }

  .wrapper .c4 {
    margin-top: 70px;
  }

  .wrapper .sidebar {
    clear: left;
  }

  .tilted {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
}

@media (width >= 1024px) {
  body {
    -webkit-text-size-adjust: none;
  }

  .wrapper {
    max-width: 960px;
  }

  .wrapper h1 {
    margin-top: 20px;
  }

  .wrapper .c1, .wrapper .c2, .wrapper .c3, .wrapper .c4 {
    float: left;
  }

  .wrapper .c2, .wrapper .c3 {
    width: 275px;
  }

  .wrapper .c2 {
    text-align: right;
    margin: 50px 0 0 80px;
    padding: 0 0 0 10px;
  }

  .wrapper .c3 {
    text-align: left;
    margin: 122px 0 0 30px;
  }

  .wrapper .c4 {
    width: 560px;
    margin: 70px 0 0 80px;
    padding-bottom: 100px;
  }

  .wrapper .sidebar {
    position: absolute;
    top: 430px;
  }

  .tilted {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  text-align: left;
  vertical-align: top;
  padding: 5px 10px;
}

th {
  font-weight: 600;
}

table.projects-index thead {
  position: sticky;
  top: 0;
}

table.projects-index td:first-child, table.projects-index th:first-child {
  width: 60%;
  padding-left: 0;
}

table.projects-index td .description, table.projects-index th .description {
  padding-bottom: 15px;
}

table.projects-index td a.red:hover, table.projects-index th a.red:hover {
  color: #df4b2b;
}

table.projects-detail {
  width: 40%;
}

table.projects-detail td {
  vertical-align: middle;
}

table.projects-detail td:first-child {
  width: 15%;
}

@media (width <= 575px) {
  table.projects-detail {
    display: block;
    overflow-x: auto;
  }
}

td.numbers, th.numbers {
  width: 5%;
}

td.numbers {
  text-align: center;
}

thead th {
  background: #ededed;
  border-color: #202020;
}

blockquote {
  margin-left: 20px;
  font-style: italic;
}

dl.upload-info {
  background-color: #fff;
  padding: .6em 1em 0;
}

dl.upload-info dt, dl.upload-info dd {
  vertical-align: top;
  margin: 0 0 .6em;
  display: inline-block;
}

dl.upload-info dt {
  width: 72%;
}

dl.upload-info dt a {
  margin-right: 6px;
}

dl.upload-info dd {
  width: 27%;
  font-weight: 400;
}

@media (width <= 600px) {
  dl.upload-info dt, dl.upload-info dd {
    width: 100%;
  }
}

.admonition, .warning {
  background-color: #fff;
  margin: 1em 0;
  padding: 0 0 .5em;
}

.admonition p, .warning p {
  margin: .3em 1em;
}

.admonition p.admonition-title, .warning p.admonition-title {
  padding-top: .5em;
  font-weight: bold;
}

.admonition code, .admonition pre, .warning code, .warning pre {
  margin: .4em 0;
}

.admonition ol, .admonition ul, .warning ol, .warning ul {
  margin: .1em .5em .5em 1em;
}

.warning {
  border: 2px solid #bb381c;
}

.warning p.admonition-title {
  color: #bb381c;
}

.github-corner:hover .octo-arm {
  animation: .56s ease-in-out octocat-wave;
}

.github-corner .octo-arm {
  transform-origin: 130px 106px;
}

.github-corner svg {
  fill: #151513;
  color: #ededed;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(-1, 1);
}

@keyframes octocat-wave {
  0%, 100% {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

@media (width <= 601px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }

  .github-corner .octo-arm {
    animation: .56s ease-in-out octocat-wave;
  }
}

.grid {
  flex-flow: wrap;
  align-content: center;
  display: -webkit-flex;
}

.grid img {
  border-radius: 5px;
  flex-grow: 1;
  width: 120px;
}

.grid .grid-cell {
  text-align: center;
  width: 120px;
  margin: 14px 14px 14px 0;
  line-height: 1;
}

iframe.optout {
  background: #fff;
  border: 0;
  width: 100%;
  height: 100%;
}

.codehilite .hll {
  background-color: #fff;
}

.codehilite .c {
  color: #999;
  font-style: italic;
}

.codehilite .err {
  color: #bb381c;
  background-color: #ededed;
}

.codehilite .k, .codehilite .o {
  color: #202020;
  font-weight: bold;
}

.codehilite .cm {
  color: #999;
  font-style: italic;
}

.codehilite .cp {
  color: #999;
  font-style: italic;
  font-weight: bold;
}

.codehilite .c1 {
  color: #999;
  font-style: italic;
}

.codehilite .cs {
  color: #999;
  font-style: italic;
  font-weight: bold;
}

.codehilite .gd {
  color: #202020;
  background-color: #ededed;
}

.codehilite .ge {
  color: #202020;
  font-style: italic;
}

.codehilite .gr {
  color: #bb381c;
}

.codehilite .gh {
  color: #999;
}

.codehilite .gi {
  color: #202020;
  background-color: #ededed;
}

.codehilite .go {
  color: #888;
}

.codehilite .gp {
  color: #555;
}

.codehilite .gs {
  font-weight: bold;
}

.codehilite .gu {
  color: #aaa;
}

.codehilite .gt {
  color: #bb381c;
}

.codehilite .kc, .codehilite .kd, .codehilite .kn, .codehilite .kp, .codehilite .kr {
  color: #202020;
  font-weight: bold;
}

.codehilite .kt {
  color: #17679b;
  font-weight: bold;
}

.codehilite .m {
  color: #17679b;
}

.codehilite .s {
  color: #bb381c;
}

.codehilite .na, .codehilite .nb {
  color: #17679b;
}

.codehilite .nc {
  color: #17679b;
  font-weight: bold;
}

.codehilite .no {
  color: #17679b;
}

.codehilite .nd {
  color: #17679b;
  font-weight: bold;
}

.codehilite .ni {
  color: #bb381c;
}

.codehilite .ne, .codehilite .nf, .codehilite .nl {
  color: #bb381c;
  font-weight: bold;
}

.codehilite .nn {
  color: #555;
}

.codehilite .nt, .codehilite .nv {
  color: #17679b;
}

.codehilite .ow {
  color: #202020;
  font-weight: bold;
}

.codehilite .w {
  color: #bbb;
}

.codehilite .mf, .codehilite .mh, .codehilite .mi, .codehilite .mo {
  color: #17679b;
}

.codehilite .sb, .codehilite .sc, .codehilite .sd, .codehilite .s2, .codehilite .se, .codehilite .sh, .codehilite .si, .codehilite .sx, .codehilite .sr, .codehilite .s1, .codehilite .ss {
  color: #bb381c;
}

.codehilite .bp {
  color: #999;
}

.codehilite .vc, .codehilite .vg, .codehilite .vi, .codehilite .il {
  color: #17679b;
}
/*# sourceMappingURL=styles.css.map */
